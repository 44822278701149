import { push } from 'connected-react-router';
import { takeLatest, select, put, all, call } from 'redux-saga/effects';
import _pick from 'lodash.pick';
import _get from 'lodash.get';

import * as StravaApi from '../../stravaApi';
import { activityUuid, printUuid } from '../../utils/uuid';
import * as routes from '../../routes';
import * as GPX from '../../utils/gpx';
import * as fileUtils from '../../utils/files';
import { fileExt } from '../../utils/strings';
import * as NumberUtils from '../../utils/numbers';
import * as SessionSelectors from '../WithSession/selectors';
import * as ThemeSelectors from '../WithThemes/selectors';
import * as ThemeActions from '../WithThemes/actions';
import * as ToasterActions from '../WithToaster/actions';
import * as ToasterConstants from '../WithToaster/constants';
import * as PrintActions from '../WithPrint/actions';
import * as PrintSelectors from '../WithPrint/selectors';
import * as AnalyticsActions from '../WithAnalytics/actions';
import { PrintActivity, StravaPrintActivity, GpxPrintActivity } from '../WithPrint/model';
import * as Selectors from './selectors';
import * as Constants from './constants';
import * as Actions from './actions';


/**
 * Sets the affiliate theme and activity.
 *
 * If a print ID or sessions doesn't yet exists then one is created.
 *
 */
export function* selectAffiliateActivities(action) {
  try {
    const themeName = yield action.themeName;
    // TODO: test for valid affiliate themeName

    const printId = yield select(PrintSelectors.idSelector);
    if (!printId) {
      const newPrintId = yield printUuid();
      yield put(PrintActions.initPrint(newPrintId));
    }

    const eventActivities = yield select(Selectors.eventActivitiesSelector);
    const selectedActivity = yield eventActivities
      .find(activity => activity.themeName === themeName);

    // These can be optionally added in WithActivities/model
    const { zoom, center, rotation, orientation } = selectedActivity;
    const printSettings = { zoom, center, rotation, orientation };

    yield put(ThemeActions.changeTheme(themeName));

    const themes = yield select(ThemeSelectors.themesSelector);
    const activityStyles = yield themes[themeName].activityStyles;


    // TODO: refactor
    // FIXME: duplicated below
    // default activities can actually contain multiple activity latlngs
    // so create/duplicate as many activities as required
    const activityCount = selectedActivity.latlngs.length
    const activityArray = activityCount === 0 ? new Array(1).fill() : selectedActivity.latlngs
    const printActivities = yield [].concat(activityArray)
      .map(latlngs => new PrintActivity({
        ...selectedActivity,
        id: activityUuid(),
        latlngs,
      }, activityStyles));


    yield put(PrintActions.setDefaultPrintActivities(printActivities, printSettings));

    // Try and update the print labels with the strava name
    yield put(PrintActions.useStravaName());
  } catch (error) {
    console.error(error);
    yield put(AnalyticsActions.trackException(error.stack, false));
    yield put(AnalyticsActions.trackError('selectAffiliateActivities', error.message));
  }
}


/**
 * User selects a pre-made activity/theme combo from the Home page
 * which is then loaded into the Print model.
 *
 * The Print session ID should be refreshed when any one of
 * these default activities is selected since we're effectivly restarting.
 *
 */
export function* selectDefaultActivity(action) {
  try {
    const { id, themeName } = yield action;
    // TODO: test for valid themeName

    // TODO: the them should be bound to the default activity, so we should
    // select the styles from that

    // Create a new Print session
    const printId = yield printUuid();
    yield put(PrintActions.initPrint(printId));

    const usersActivities = yield select(PrintSelectors.userPrintActivitiesSelector);
    const defaultActivities = yield select(Selectors.defaultActivitiesSelector);

    // get the themeName from the selected default activity
    // TODO: this should be a function
    const random = NumberUtils.random(0, defaultActivities.length - 1);
    const selectedActivity = yield defaultActivities.find(activity => activity.id === id)
      || defaultActivities[random];

    yield put(ThemeActions.changeTheme(themeName || selectedActivity.themeName));

    // FIXME: we have to manually pull the activity styles because
    // changing the theme above executes async
    const themes = yield select(ThemeSelectors.themesSelector);
    const activityStyles = yield themes[selectedActivity.themeName].activityStyles;

    if (usersActivities && usersActivities.length) {
      yield put(PrintActions.setAllPrintActivityStyles(activityStyles));
    } else {
      // TODO: refactor
      // default activities can actually contain multiple activity latlngs
      // so create/duplicate as many activities as required
      const printActivities = yield [].concat(selectedActivity.latlngs)
        .map((_latlngs, i) => new PrintActivity({
          ...selectedActivity,
          id: activityUuid(),
          latlngs: _get(selectedActivity, ['latlngs', i]),
          elevations: _get(selectedActivity, ['elevations', i]),
        }, activityStyles));
      yield put(PrintActions.setDefaultPrintActivities(printActivities));
    }


    // Try and update the print labels with the strava name
    yield put(PrintActions.useStravaName());

    // Navigate to the Create page
    yield put(push(`${routes.CREATE}`));
  } catch (error) {
    console.error(error);
    yield put(AnalyticsActions.trackException(error.stack, false));
    yield put(AnalyticsActions.trackError('selectDefaultActivity', error.message));
  }
}


/**
 * Handles multiple calls to uploadXmlFile
 *
 */
export function* uploadXmlFiles(action) {
  try {
    const files = yield action.files;
    yield put(Actions.activitiesLoadingCount(files.length));
    const forks = [];
    for (const file of files) { // eslint-disable-line
      forks.push(call(uploadXmlFile, file));
    }
    yield all(forks);
    yield put(Actions.activitiesLoadingCount(0));
  } catch (error) {
    console.error(error);
    yield put(AnalyticsActions.trackException(error.stack, false));
    yield put(AnalyticsActions.trackError('uploadXmlFiles', error.message));
  }
}


/**
 * Handles reading an uploaded file and parsing GPX/KML
 *
 */
export function* uploadXmlFile(file) {
  try {
    const { name } = yield file;
    const gpxModels = yield GPX.extractGpxModelsFromFile(file, name);
    const activityStyles = yield select(ThemeSelectors.themeActivityStylesSelector);
    const gpxPrintActivities = yield gpxModels
      .map(gpxModel => new GpxPrintActivity(gpxModel, activityStyles));
    yield put(PrintActions.addPrintActivities(gpxPrintActivities));
    const ext = yield fileExt(name).toUpperCase();
    yield put(AnalyticsActions.trackActivityAdded(ext));
    // Try and update the print labels with the strava name
    yield put(PrintActions.useStravaName());
  } catch (error) {
    console.error(error);
    yield put(ToasterActions.createToast(ToasterConstants.ERR_FILE_UPLOAD));
    yield put(AnalyticsActions.trackException(error.stack, false));
    yield put(AnalyticsActions.trackError('uploadXmlFile', error.message));
  }
}


/**
 * Handles uploaded JSON files
 *
 */
export function* uploadJsonFiles(action) {
  try {
    const files = yield action.files;
    const firstFile = files[0];
    const printJson = yield fileUtils.parseJsonFile(firstFile);
    if (printJson && printJson.id && printJson.themeName) {
      yield put(PrintActions.replacePrintWithJsonSaga(printJson));
    }
  } catch (error) {
    console.error(error);
    yield put(ToasterActions.createToast(ToasterConstants.ERR_FILE_UPLOAD));
    yield put(AnalyticsActions.trackException(error.stack, false));
    yield put(AnalyticsActions.trackError('uploadJsonFiles', error.message));
  }
}


// -----------------------------------------------------------------------------
// STRAVA ACTIVITIES
// -----------------------------------------------------------------------------


/**
 * Fetch both stream and activity details
 * {action.ids} can be either a single ID string, number or an array of either.
 *
 */
export function* getStravaActivityStreamsAndDetails(action) {
  try {
    const accessToken = yield select(SessionSelectors.accessTokenSelector);
    const ids = yield all([].concat(action.ids));
    yield put(Actions.activitiesLoadingCount(ids.length));
    const stravaActivities = yield all(ids.map(id =>
      call(mergeStreamsAndDetails, StravaApi.apiGetStravaActivity, StravaApi.apiGetStravaActivityStream, id, accessToken)));
    yield handleStravaFetchErrors(stravaActivities);
    const activityStyles = yield select(ThemeSelectors.themeActivityStylesSelector);
    const activities = yield stravaActivities
      .filter(activity => !!activity.data)
      .map(stravaActivity => new StravaPrintActivity(stravaActivity.data, activityStyles));
    yield put(PrintActions.addPrintActivities(activities));
    yield put(AnalyticsActions.trackActivityAdded('Strava'));
    yield put(Actions.activitiesLoadingCount(0));
    // Try and update the print labels with the strava name
    yield put(PrintActions.useStravaName());
  } catch (error) {
    console.error(error);
    yield put(AnalyticsActions.trackException(error.stack, false));
    yield put(AnalyticsActions.trackError('getStravaActivityStreamsAndDetails', error.message));
  }
}


/**
 * Fetch one or more strava activites.
 * {action.ids} can be either a single ID string, number or an array of either.
 *
 */
export function* getStravaActivities(action) {
  try {
    const accessToken = yield select(SessionSelectors.accessTokenSelector);
    const ids = yield all([].concat(action.ids));
    yield put(Actions.activitiesLoadingCount(ids.length));
    const stravaActivities = yield all(ids.map(id => call(StravaApi.apiGetStravaActivity, id, accessToken)));
    yield handleStravaFetchErrors(stravaActivities);
    const activityStyles = yield select(ThemeSelectors.themeActivityStylesSelector);
    const activities = yield stravaActivities
      .filter(activity => !!activity.data)
      .map(stravaActivity => new StravaPrintActivity(stravaActivity.data, activityStyles));
    yield put(PrintActions.addPrintActivities(activities));
    yield put(AnalyticsActions.trackActivityAdded('Strava'));
    yield put(Actions.activitiesLoadingCount(0));
    // Try and update the print labels with the strava name
    yield put(PrintActions.useStravaName());
  } catch (error) {
    console.error(error);
    yield put(AnalyticsActions.trackException(error.stack, false));
    yield put(AnalyticsActions.trackError('getStravaActivities', error.message));
  }
}


// -----------------------------------------------------------------------------
// STRAVA ROUTES
// -----------------------------------------------------------------------------


/**
 * Fetch one or more strava routes.
 * {action.ids} can be either a single ID string, number or an array of either.
 *
 */
export function* getStravaRoutes(action) {
  try {
    const accessToken = yield select(SessionSelectors.accessTokenSelector);
    const ids = yield all([].concat(action.ids));
    yield put(Actions.activitiesLoadingCount(ids.length));
    const stravaRoutes = yield all(ids.map(id => call(StravaApi.apiGetStravaRoute, id, accessToken)));
    yield handleStravaFetchErrors(stravaRoutes);
    const activityStyles = yield select(ThemeSelectors.themeActivityStylesSelector);
    const activities = yield stravaRoutes
      .filter(route => !!route.data)
      .map(route => new StravaPrintActivity(route.data, activityStyles));
    yield put(PrintActions.addPrintActivities(activities));
    yield put(AnalyticsActions.trackActivityAdded('Strava'));
    yield put(Actions.activitiesLoadingCount(0));
    yield put(PrintActions.useStravaName());
  } catch (error) {
    console.error(error);
    yield put(AnalyticsActions.trackException(error.stack, false));
    yield put(AnalyticsActions.trackError('getStravaRoutes', error.message));
  }
}


/**
 * Fetch both stream and route details
 * {action.ids} can be either a single ID string, number or an array of either.
 *
 */
export function* getStravaRoutesStreamsAndDetails(action) {
  try {
    const accessToken = yield select(SessionSelectors.accessTokenSelector);
    const ids = yield all([].concat(action.ids));
    yield put(Actions.activitiesLoadingCount(ids.length));
    const stravaRoutes = yield all(ids.map(id =>
      call(mergeStreamsAndDetails, StravaApi.apiGetStravaRoute, StravaApi.apiGetStravaRouteStream, id, accessToken)));
    yield handleStravaFetchErrors(stravaRoutes);
    const activityStyles = yield select(ThemeSelectors.themeActivityStylesSelector);
    const activities = yield stravaRoutes
      .filter(route => !!route.data)
      .map(route => new StravaPrintActivity(route.data, activityStyles));
    yield put(PrintActions.addPrintActivities(activities));
    yield put(AnalyticsActions.trackActivityAdded('Strava'));
    yield put(Actions.activitiesLoadingCount(0));
    // Try and update the print labels with the strava name
    yield put(PrintActions.useStravaName());
  } catch (error) {
    console.error(error);
    yield put(AnalyticsActions.trackException(error.stack, false));
    yield put(AnalyticsActions.trackError('getStravaRoutesStreamsAndDetails', error.message));
  }
}


// -----------------------------------------------------------------------------
// UTILS
// -----------------------------------------------------------------------------


/**
 * Will filter activity response payloads for errors and
 * put Toaster nofications to the UI
 *
 */
export function* handleStravaFetchErrors(responses = []) {
  const emptyResponses = responses
    .filter(error => error.data === null || error.status === 404);
  const errorIds = emptyResponses.map(error => error.errors.id);

  if (errorIds.length) {
    const dict = { p: '', s: '' };
    const resourceType = _get(emptyResponses, ['0', 'errors', 'errors', '0', 'resource'], '').toLowerCase();
    switch (resourceType) {
      case 'route': {
        dict.p = 'routes';
        dict.s = 'route';
        break;
      }
      default: {
        dict.p = 'activities';
        dict.s = 'activity';
        break;
      }
    }

    const errorIdsStr = errorIds.join(', ');
    // eslint-disable-next-line no-nested-ternary
    const message = (errorIds.length > 1)
      ? `Sorry, we couldn't find these ${dict.p}: ${errorIdsStr}`
      // if we've requested multiple IDs the grammar should be specific
      : responses.length
        ? `Sorry, we couldn't find ${dict.s} ${errorIdsStr}`
        : `Sorry, we couldn't find that ${dict.s}`;

    yield put(ToasterActions.createToast(message, ToasterConstants.TIMEOUT_DEFAULT));
  }
}


/**
 * Proxy to call both streams and details for any
 * Strava activity or route. Just pass in the api function
 * for both details and streams
 *
 * @param {function} apiDetails
 * @param {function} apiStreams
 * @param {string|number} id
 * @param {string} accessToken
 *
 */
export function* mergeStreamsAndDetails(apiDetails, apiStreams, id = null, accessToken = null) {
  const [details, streams] = yield all([
    call(apiDetails, id, accessToken),
    call(apiStreams, id, accessToken),
  ]);
  return concatDetailsAndStreamData(details, streams);
}


/**
 * Concats stream data into a Strava activity's detail object
 * @param {object} details
 * @param {object} streams
 */
export function concatDetailsAndStreamData(details, streams) {
  if (details.data && streams.data) {
    if (Array.isArray(streams.data)) {
      streams.data = streams.data.reduce((acc, stream) => {
        acc[stream.type] = { ...stream };
        return acc;
      }, {});
    }
    details.data.streams = {
      // Strava's streams always seem to include distance,
      // so only pick what we want
      ..._pick(streams.data, StravaApi.STRAVA_STREAM_SETS),
    };
  }
  return details;
}


// -----------------------------------------------------------------------------
// SAGAS
// -----------------------------------------------------------------------------


export function* watchSelectAffiliateActivities() {
  yield takeLatest(Constants.SELECT_AFFILIATE_ACTIVITIES, selectAffiliateActivities);
}

export function* watchSelectDefaultActivity() {
  yield takeLatest(Constants.SELECT_DEFAULT_ACTIVITY, selectDefaultActivity);
}

export function* watchuploadXmlFiles() {
  yield takeLatest(Constants.UPLOAD_XML_FILES, uploadXmlFiles);
}

export function* watchuploadJsonFiles() {
  yield takeLatest(Constants.UPLOAD_JSON_FILES, uploadJsonFiles);
}

export function* watchGetStravaActivities() {
  yield takeLatest(Constants.GET_STRAVA_ACTIVITIES_REQUEST, getStravaActivities);
}

export function* watchGetStravaActivityStreamsAndDetails() {
  yield takeLatest(
    Constants.GET_STRAVA_ACTIVITY_STREAMS_AND_DETAILS_REQUEST,
    getStravaActivityStreamsAndDetails,
  );
}

export function* watchGetStravaRoutes() {
  yield takeLatest(Constants.GET_STRAVA_ROUTES_REQUEST, getStravaRoutes);
}

export function* watchGetStravaRoutesStreamsAndDetails() {
  yield takeLatest(
    Constants.GET_STRAVA_ROUTES_STREAMS_AND_DETAILS_REQUEST,
    getStravaRoutesStreamsAndDetails,
  );
}

export default [
  watchSelectAffiliateActivities,
  watchSelectDefaultActivity,
  watchGetStravaActivities,
  watchGetStravaActivityStreamsAndDetails,
  watchuploadXmlFiles,
  watchuploadJsonFiles,
  watchGetStravaRoutes,
  watchGetStravaRoutesStreamsAndDetails,
];
