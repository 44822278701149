import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Svg from 'react-inlinesvg';

import { colours, fonts } from '../../theme';
import { SmallText } from '../Text';

import logoSvg from './logo-pt.svg';
import mapboxSvg from './mapbox-logo.svg';
import stravaLogoSvg from './logo-strava.svg';
import logoSvgLight from './logo-pt-light.svg';
import logoSvgInline from './logo-pt-inline.svg';
import poweredByStrava from './powered-by-strava.svg';
import poweredByStravaAlt from './powered-by-strava-alt.svg';
import compatibleWithStrava from './compatible-with-strava.svg';
import logoCreditsSvg from './logo-credits.svg';

const UnstyledLogo = ({ animate, ...props }) => <Svg {...props} src={props.light ? logoSvgLight : logoSvg} />;
const UnstyledLogoInline = ({ animate, ...props }) => <Svg {...props} src={logoSvgInline} />;
const UnstyledStravaLogo = props => <Svg {...props} src={stravaLogoSvg} />;
const UnstyledCompatibleWithStrava = props => <Svg {...props} src={compatibleWithStrava} />;
const UnstyledPoweredByStrava = props => <Svg {...props} src={poweredByStrava} />;
const UnstyledPoweredByStravaAlt = props => <Svg {...props} src={poweredByStravaAlt} />;
const UnstyledGpxLogo = props => <SmallText {...props}>GPX</SmallText>;
const UnstyledMapboxLogo = props => <Svg {...props} src={mapboxSvg} />;
const UnstyledLogoCredits = props => <Svg {...props} src={logoCreditsSvg} />;

UnstyledLogo.propTypes = {
  animate: PropTypes.bool,
  light: PropTypes.bool,
};
UnstyledLogo.defaultProps = {
  animate: false,
  light: false,
};

UnstyledLogoInline.propTypes = UnstyledLogo.propTypes;
UnstyledLogoInline.defaultProps = UnstyledLogo.defaultProps;

const logoStyles = css`
  display: inline-block;

  svg {
    height: 100%;
    width: 100%;
  }

  .svg-route {
    stroke: ${p => p.light ? colours.white : colours.secondaryDark};
  }

  .svg-text {
    fill: ${p => p.light ? colours.white : colours.secondaryDark};
  }

  .svg-grad-stop1 {
    stop-color: ${colours.logoGradient1};
  }
  .svg-grad-stop2 {
    stop-color: ${colours.logoGradient2};
  }
  .svg-grad-stop3 {
    stop-color: ${colours.logoGradient3};
  }

  ${props => props.animate && css`
    @keyframes a-draw {
      to { stroke-dashoffset: 0; }
    }

    @keyframes a-reveal-upper {
      to { transform: translateX(0); opacity: 1; }
    }

    .svg-route {
      fill: transparent;
      stroke: ${p => p.light ? colours.white : colours.secondaryDark};
      stroke-dasharray: 240, 240;
      stroke-dashoffset: 240;

      animation: a-draw 0.8s linear 0.4s forwards;
    }


    .svg-text-upper,
    .svg-text-lower {
      transform: translateX(-4%);
      opacity: 0;
    }

    .svg-text-upper {
      animation: a-reveal-upper 1s ease 1s forwards;
    }

    .svg-text-lower {
      animation: a-reveal-upper 1s ease 1.2s forwards;
    }
  `}
`;

export const Logo = styled(UnstyledLogo)`
  ${logoStyles}
`;

export const LogoInline = styled(UnstyledLogoInline)`
  ${logoStyles}
`;

export const StravaLogo = styled(UnstyledStravaLogo)`
  display: inline-block;

  svg {
    height: 100%;
    width: 100%;
  }
`;

export const CompatibleWithStrava = styled(UnstyledCompatibleWithStrava)`
  display: inline-block;

  .text {
    fill: currentColor;
  }

  .strava {
    fill: #fc4c02; /* Strava official color */
  }

  svg {
    height: 100%;
    width: 100%;
    display: block;
  }
`;

export const PoweredByStrava = styled(UnstyledPoweredByStrava)`
  display: inline-block;

  svg {
    height: 100%;
    width: 100%;
    fill: currentColor;
  }
`;
export const PoweredByStravaAlt = styled(UnstyledPoweredByStravaAlt)`
  display: inline-block;

  svg {
    height: 100%;
    width: 100%;
  }
`;

export const MapboxLogo = styled(UnstyledMapboxLogo)`
  display: inline-block;
  color: #4264fb; /* mapbox official colour */
  svg {
    height: 100%;
    width: 100%;
    display: block;
  }
`;

// eslint-disable-next-line react/jsx-no-comment-textnodes
export const LogoCredits = styled(UnstyledLogoCredits)`
  color: currentColor;
  display: block;

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`;

export const GpxLogo = styled(UnstyledGpxLogo)`
  font-weight: ${fonts.weightMedium};
  background-color: ${colours.white};
  line-height: 1;
  border-radius: 3px;
  padding: 8px 8px 6px;
`;

export default Logo;
