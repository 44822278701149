import styled from 'styled-components';

import { dimensions } from '../../theme';
import { media } from '../../styles';

const SiteContainer = styled('div')`
  width: 100%;
  max-width: ${dimensions.siteMaxWidth};
  margin: auto;
  padding-left: ${dimensions.siteGutter};
  padding-right: ${dimensions.siteGutter};

  /* MEDIA: NAV */
  ${media.nav`
    padding-left: 3rem;
    padding-right: 3rem;
  `}
`;

export default SiteContainer;
