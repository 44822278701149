import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { bindActionCreators, compose } from 'redux';
import ScriptLoader from 'react-script-loader-hoc';
import { Elements, StripeProvider } from 'react-stripe-elements';


import * as Actions from './actions';
import * as Selectors from './selectors';
import { STRIPE_SRC } from './constants';

import font400 from '../../fonts/apercu-400-woff';
import font500 from '../../fonts/apercu-500-woff';

const { REACT_APP_STRIPE_API_KEY } = process.env;

const StripeHoc = WrappedComponent =>
  // eslint-disable-next-line
  class WithHoc extends React.Component {

    static displayName = `withStripeHoc(${
      WrappedComponent.displayName ||
      WrappedComponent.name ||
      'Component'
    })`;

    static propTypes = {
      stripeToken: PropTypes.shape({}),
      scriptsLoadedSuccessfully: PropTypes.bool.isRequired,
      newStripeToken: PropTypes.func.isRequired,
    };

    static defaultProps = {
      stripeToken: undefined,
    };

    componentWillReceiveProps(nextProps) {
      const noToken = !nextProps.stripeToken;
      const scriptLoaded = nextProps.scriptsLoadedSuccessfully;
      if (noToken && scriptLoaded) {
        this.props.newStripeToken(window.Stripe(REACT_APP_STRIPE_API_KEY));
      }
    }

    getFonts() {
      // https://stripe.com/docs/stripe-js/reference#elements-options
      return [{
        family: 'Apercu',
        src: `${font400}`,
        weight: 400,
      }, {
        family: 'Apercu',
        src: `${font500}`,
        weight: 500,
      }];
    }

    render() {
      if (!this.props.stripeToken) return <WrappedComponent {...this.props} />;
      return (
        <StripeProvider stripe={this.props.stripeToken}>
          <Elements fonts={this.getFonts()}><WrappedComponent {...this.props} /></Elements>
        </StripeProvider>
      );
    }
  };

export const MakeWithStripeHoc = WrappedComponent => new StripeHoc(WrappedComponent);

export const WithStripeHoc = () => (WrappedComponent) => {
  const WithStripe = MakeWithStripeHoc(WrappedComponent);

  const mapStateToProps = createStructuredSelector({
    stripeToken: Selectors.tokenSelector,
  });

  const mapDispatchToProps = dispatch => bindActionCreators({
    newStripeToken: Actions.newStripeToken,
  }, dispatch);

  const withConnect = connect(mapStateToProps, mapDispatchToProps);

  const composed = compose(
    ScriptLoader(STRIPE_SRC),
    withConnect,
  )(WithStripe);

  return composed;
};

export default WithStripeHoc;
