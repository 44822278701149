import React from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'

import * as routes from '../../routes'
import { fonts } from '../../theme'
import { media } from '../../styles'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import { TopoBackground } from '../../components/Background'
import SiteHeader from '../../containers/SiteHeader'
import SiteContainer from '../../components/SiteContainer'
import { FeaturedTitle } from '../../components/Title'
import { LinkAsButton } from '../../components/Link'

const LinkButton = styled(LinkAsButton)`
  border-color: currentColor;
  font-size: ${fonts.size03};
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
`

export const UnstyledLinks = (props) => (
  <React.Fragment>
    <ScrollToTopOnMount />
    <Helmet>
      <title>Links</title>
    </Helmet>
    <div className={props.className}>
      <TopoBackground topo={2} />
      <SiteHeader animateLogo />
      <SiteContainer>
        <article>
          {/* <header>
            <FeaturedTitle tag="h1">
              Links
            </FeaturedTitle>
          </header> */}
          <section>
            <LinkButton to="https://papertrails.io/events/tribe-x-maverick-run-free">
              Tribe x Maverick Prints
            </LinkButton>
            <LinkButton to="https://papertrails.io/events/greatbritishdivide">
              Great British Divide Prints
            </LinkButton>
            <LinkButton to="https://papertrails.io/events/pancelticultra">
              Pan Celtic Ultra Prints
            </LinkButton>
            <LinkButton to="https://papertrails.io/events/gravelrally/">
              Gravel Rally Prints
            </LinkButton>
            <LinkButton to={routes.HOME}>Main Website</LinkButton>
            {/* <LinkButton to={routes.SHOP}>Shop</LinkButton> */}
          </section>
        </article>
      </SiteContainer>
    </div>
  </React.Fragment>
)

export const Links = styled(UnstyledLinks)`
  width: 100%;
  position: relative;
  overflow: hidden;
  min-height: 95vh;

  > ${SiteContainer} {
    margin-bottom: 16rem;
  }

  ${FeaturedTitle} {
    text-align: center;
    margin-top: 7rem;
    margin-bottom: 21.5rem;
  }

  ${LinkButton} {
    margin: 0 auto 6rem;
    max-width: 70rem;
  }

  ${TopoBackground} {
    top: 40vh;
  }

  ${media.nav`
    min-height: 80vh;

    > ${SiteContainer} {
      margin-bottom: 16rem;
    }

    ${FeaturedTitle} {
      margin-top: 0;
      margin-bottom: 8rem;
    }

    ${LinkButton} {
      font-size: 2.2rem;
      padding-top: 1.8rem;
      padding-bottom: 1.8rem;
      margin-bottom: 3rem;
    }

    ${TopoBackground} {
      top: 28vh;
    }
  `}
`

export default Links
