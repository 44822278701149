import React from 'react'
import _get from 'lodash.get'
import { compose } from 'redux'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Immutable from 'seamless-immutable'

import * as routes from '../../routes'
import { colours, fonts } from '../../theme'
import { media, zIndexes } from '../../styles'
import { historyPropTypes } from '../../proptypes'
import { formatCurrency } from '../../utils/numbers'
import { calcCartTotal, calcCartOriginalTotal } from '../../utils/cart'

import WithPrint from '../../hocs/WithPrint'
import WithStripe from '../../hocs/WithStripe'
import WithPaypal from '../../hocs/WithPaypal'
import WithOrders from '../../hocs/WithOrders'
import WithSession from '../../hocs/WithSession'
import WithToaster from '../../hocs/WithToaster'
import WithAnalytics from '../../hocs/WithAnalytics'
import initialState from '../../hocs/WithOrders/model'
import { TIMEOUT_DEFAULT } from '../../hocs/WithToaster/constants'

import Emoji from '../../components/Emoji'
import SiteHeader from '../../containers/SiteHeader'
import { FeaturedTitle } from '../../components/Title'
import CheckoutForm from '../../components/CheckoutForm'
import SiteContainer from '../../components/SiteContainer'
import { TopoBackground } from '../../components/Background'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'

const FEATURE_PAYPAL_BUTTON = process.env.REACT_APP_FEATURE_PAYPAL_BUTTON

class UnstyledCheckout extends React.Component {
  static propTypes = {
    printId: PropTypes.string.isRequired,
    orderInProgress: PropTypes.bool.isRequired,
    stripeActionRequired: PropTypes.bool,
    intentSecret: PropTypes.string,
    stripeToken: PropTypes.shape({}),
    currentProduct: PropTypes.shape({}),
    printQuantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    shippingCountries: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    ).isRequired,
    history: historyPropTypes.isRequired,
    createToast: PropTypes.func.isRequired,
    setOrderInProgress: PropTypes.func.isRequired,
    createStripeOrder: PropTypes.func.isRequired,
    confirmStripeOrder: PropTypes.func.isRequired,
    createPaypalOrder: PropTypes.func.isRequired,
    setPrintQuantity: PropTypes.func.isRequired,
    getCoupon: PropTypes.func.isRequired,
    couponCheckInProgress: PropTypes.func.isRequired,
    couponId: PropTypes.string,
    trackCtaClicked: PropTypes.func.isRequired,
  }

  static defaultProps = {
    currentProduct: undefined,
    printQuantity: 1,
    stripeToken: undefined,
    stripeActionRequired: initialState.stripeActionRequired,
    intentSecret: initialState.intentSecret,
    couponId: undefined,
  }

  constructor(props) {
    super(props)
    if (!this.props.printId) {
      this.props.createToast(
        "Looks like you either took too long or you haven't created a print yet.",
        TIMEOUT_DEFAULT,
      )
      this.props.history.replace(routes.CREATE)
    }
  }

  onStripePaymentMethodCreated = (paymentMethodId, userData) => {
    this.props.createStripeOrder(paymentMethodId, userData)
  }

  onStripePaymentIntentConfirmed = (paymentIntentId, userData) => {
    this.props.confirmStripeOrder(paymentIntentId, userData)
  }

  onPaypalOrderConfirmed = (paypalOrderId, coupon) => {
    this.props.createPaypalOrder(paypalOrderId, coupon)
  }

  onPaypalError = () => {
    this.props.createToast(
      'Derp! Something went wrong with PayPal ',
      TIMEOUT_DEFAULT,
    )
  }

  onValidationError = (error) => {
    const { message } = error
    this.props.createToast(message)
    this.props.setOrderInProgress(false)
  }

  onQuantityChange = (evt) => {
    this.props.setPrintQuantity(evt.target.value)
  }

  onApplyCoupon = (couponId) => {
    this.props.getCoupon(couponId)
  }

  formatTotal(total = 0) {
    const currency = _get(this.props, 'currentProduct.currency', 'GBP')
    return formatCurrency(total / 100, currency)
  }

  trackCtaClicked = this.props.trackCtaClicked

  get totalPrice() {
    const quantityPrice = calcCartTotal(
      this.props.currentProduct,
      this.props.printQuantity,
    )
    return quantityPrice
  }

  get originalPrice() {
    const quantityPrice = calcCartOriginalTotal(
      this.props.currentProduct,
      this.props.printQuantity,
    )
    if (quantityPrice === 0) return null
    return this.formatTotal(quantityPrice)
  }

  renderStripeLoading() {
    return (
      <React.Fragment>
        <header>
          <FeaturedTitle tag="h1">
            We're just setting up our payment interface, won't be a moment…
          </FeaturedTitle>
        </header>
      </React.Fragment>
    )
  }

  renderOrderForm() {
    return (
      <React.Fragment>
        <header>
          <FeaturedTitle tag="h1">
            Nearly there! <br />
            Just need a few details from you&hellip; <br />
            <Emoji emoji="metal" />
          </FeaturedTitle>
        </header>
        <CheckoutForm
          stripeActionRequired={this.props.stripeActionRequired}
          intentSecret={this.props.intentSecret}
          price={this.totalPrice}
          originalPrice={this.originalPrice}
          quantity={this.props.printQuantity}
          currentProduct={this.props.currentProduct}
          countries={Immutable.asMutable(this.props.shippingCountries)}
          inProgress={this.props.orderInProgress}
          couponCheckInProgress={this.props.couponCheckInProgress}
          couponId={this.props.couponId}
          onApplyCoupon={this.onApplyCoupon}
          onQuantityChange={this.onQuantityChange}
          onValidationError={this.onValidationError}
          onStripePaymentMethodCreated={this.onStripePaymentMethodCreated}
          onStripePaymentIntentConfirmed={this.onStripePaymentIntentConfirmed}
          onPaypalError={this.onPaypalError}
          onPaypalOrderConfirmed={this.onPaypalOrderConfirmed}
          trackCtaClicked={this.trackCtaClicked}
        />
      </React.Fragment>
    )
  }

  render() {
    return (
      <React.Fragment>
        <ScrollToTopOnMount />
        <Helmet>
          <title>Checkout</title>
          <link rel="preconnect" href="https://js.stripe.com" />
        </Helmet>
        <div className={this.props.className}>
          <TopoBackground topo={2} />
          <SiteHeader />
          <SiteContainer>
            {!this.props.stripeToken
              ? this.renderStripeLoading()
              : this.renderOrderForm()}
          </SiteContainer>
        </div>
      </React.Fragment>
    )
  }
}

UnstyledCheckout.propTypes = {}
UnstyledCheckout.defaultProps = {}

const Checkout = styled(UnstyledCheckout)`
  width: 100%;
  overflow: hidden;
  text-align: center;
  position: relative;

  ${FeaturedTitle} {
    text-align: center;
    margin: 0 auto 5rem;
    max-width: 18em;
    position: relative;

    &:before {
      line-height: 1;
      content: '2 / 2';
      color: ${colours.grey03};
      font-size: ${fonts.size01};
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: -5rem;
    }
  }

  ${CheckoutForm} {
    width: 100%;
    text-align: left;
    margin: 5rem auto 23rem;
  }

  ${FeaturedTitle} {
    z-index: ${zIndexes.raised};
    position: relative;
  }

  ${TopoBackground} {
    z-index: ${zIndexes.zero};
    top: 5%;
    opacity: 0.8;
  }

  /* MEDIA STYLES */

  ${media.nav`
    margin-bottom: 0rem;

    ${CheckoutForm} {
      margin-bottom: 6rem;
    }

    ${FeaturedTitle} {
      margin-bottom: 6rem;
      &:before {
        top: -3rem;
      }
    }

  `}
`

const withStripe = WithStripe()
const withPaypal = FEATURE_PAYPAL_BUTTON ? WithPaypal() : null
const withPrint = WithPrint()
const withSession = WithSession()
const withOrders = WithOrders()
const withToaster = WithToaster()
const withAnalytics = WithAnalytics()

const composedHocs = [
  withOrders,
  withPrint,
  withSession,
  withStripe,
  withPaypal,
  withToaster,
  withAnalytics,
].filter(Boolean)

export { Checkout, UnstyledCheckout }
export default compose(...composedHocs)(Checkout)
