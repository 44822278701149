/* istanbul ignore file */
const { REACT_APP_DEBUG_KONAMI, REACT_APP_DEBUG_GODMODE } = process.env;

// ↑ ↑ ↓ ↓ ← → ← → B A
// eslint-disable-next-line
const konamiFn = (f, a) => { document.onkeyup = function (e) { /113302022928$/.test(a += '' + ((e || self.event).keyCode - 37)) && f() } }

export const konami = cb => REACT_APP_DEBUG_KONAMI
  ? konamiFn(cb)
  : () => {};

  // iddqd
  // eslint-disable-next-line
const godModeFn = (f, a) => { document.onkeyup = function (e) { /611141$/.test(a += '' + ((e || self.event).keyCode - 67)) && f() } }

export const godMode = cb => REACT_APP_DEBUG_GODMODE
  ? godModeFn(cb)
  : () => {};
