/* eslint-disable no-bitwise */
import convert from 'convert-units';
import _omit from 'lodash.omit';

import { printUuid } from './uuid';
import { padUnit } from './numbers';
import { removeFalsy } from './arrays';
import { printDatePrefix } from './dates';
import { eventThemeNames } from '../hocs/WithThemes/model';
import { activitySources } from '../hocs/WithActivities/constants';
import { ORIENTATION_PORTRAIT, ORIENTATION_LANDSCAPE } from '../hocs/WithPrint/constants';

const DEFAULT_LOCALE = 'en-GB';


/**
 * Converts distances between different units
 * @param {number|string} distance
 * @param {string} inputUnit
 * @param {string} outputUnit
 * @param {number} decimals
 * @param {string} locale
 */
export const formatDistance = (distance = 0, inputUnit = 'mi', outputUnit = 'km', decimals = 0, locale = DEFAULT_LOCALE) => {
  const value = convert(distance).from(inputUnit).to(outputUnit);
  if (!value) return undefined;
  const clampedDecimals = parseFloat(value.toFixed(decimals));
  const localeString = clampedDecimals.toLocaleString(locale);
  return `${localeString}${outputUnit}`;
};


/**
 * Returns the total elevation between two different units
 * @param {number|string} elevation
 * @param {string} inputUnit
 * @param {string} outputUnit
 * @param {number} decimals
 * @param {string} locale
 */
export const formatElevation = (elevation = 0, inputUnit = 'km', outputUnit = 'km', decimals = 0, locale = DEFAULT_LOCALE) =>
  formatDistance(parseFloat(elevation), inputUnit, outputUnit, decimals, locale);


/**
 * Takes an array of altitude measurements and computes the gain
 * using the elevation difference as long as the current elevation value is
 * higher than than the previous one (i.e. going up)
 * @param {number[]} altitudes
 */
export const calcElevationGain = (altitudes = []) => {
  let elevationGain = 0;
  for (let i = 0; i < altitudes.length; i++) {
    const currentElevation = altitudes[i];
    const previousElevation = altitudes[i - 1];
    const elevationDiff = (currentElevation - previousElevation);
    if (elevationDiff > 0) elevationGain += elevationDiff;
  }
  return parseFloat(elevationGain.toFixed(2));
};


/**
 * Converts a single unit amount to ww:dd:hh:mm:ss
 * @param {number} duration
 * @param {string} inputUnit
 */
export const formatDuration = (duration = 0, inputUnit = 'seconds') => {
  const dur = deconstructDuration(duration, inputUnit);
  const units = [dur.weeks, dur.days, dur.hours, dur.minutes, dur.seconds];
  const nonZeroUnits = units.filter(unit => unit !== 0);
  if (!nonZeroUnits.length) return undefined;
  return nonZeroUnits.map(padUnit).join(':');
};


/**
 * Takes a duration of a specified unit and returns an object
 * of broken-down time components, such as hours, minutes, weeks, etc.
 * @param {number} duration
 * @param {string} inputUnit
 */
export const deconstructDuration = (duration = 0, inputUnit = 'seconds') => {
  let secondsTotal = 0;
  switch (inputUnit) {
    case 'ms':
    case 'milliseconds':
      secondsTotal = duration / 1000;
      break;
    case 'minutes':
      secondsTotal = duration * 60;
      break;
    case 'hours':
      secondsTotal = duration * 3600;
      break;
    default: // seconds
      secondsTotal = duration;
      break;
  }

  const seconds = secondsTotal % 60 || 0;
  const minutesTotal = ~~(secondsTotal / 60);
  const minutes = minutesTotal % 60 || 0;
  const hoursTotal = ~~(minutesTotal / 60);
  const hours = hoursTotal % 24 || 0;
  const daysTotal = ~~(hoursTotal / 24);
  const days = daysTotal % 7 || 0;
  const weeksTotal = ~~(daysTotal / 7);
  const weeks = weeksTotal % 52 || 0;

  return {
    seconds,
    secondsTotal,
    minutes,
    minutesTotal,
    hours,
    hoursTotal,
    days,
    daysTotal,
    weeks,
    weeksTotal,
  };
};


/**
 * Returns the number of calories in locale format
 * @param {number|string} calories
 */
export const formatCalories = (calories = 0) => {
  if (!calories) return undefined;
  return Math.round(parseInt(calories, 10)).toLocaleString(DEFAULT_LOCALE);
};


/**
 * Converts pace from metric (km) to imperial (optionally) and
 * returns a human readable format.
 * @param {number} pace
 * @param {boolean} imperial
 */
export const formatPace = (pace = 0, inputUnit = 'min/km', outputUnit = 'min/km') => {
  if (!pace) return undefined;
  pace = convert(pace).from(inputUnit).to(outputUnit);
  const displayedUnit = outputUnit.split('/').pop();
  const duration = deconstructDuration(pace, 'minutes');
  return `${duration.minutes}:${padUnit(Math.round(duration.seconds))}/${displayedUnit}`;
};


/**
 * Converts between specified [speed units](https://github.com/ben-ng/convert-units/blob/master/lib/definitions/speed.js)
 * @param {number} speed
 * @param {string} inputUnit
 * @param {string} outputUnit
 * @param {number} decimalCount
 */
export const formatSpeed = (speed = 0, inputUnit = 'm/s', outputUnit = 'm/s', decimalCount = 1) => {
  if (!speed) return undefined;

  // remapping units to match the API
  const _outputUnit = outputUnit;
  if (inputUnit === 'mi/h') inputUnit = 'm/h';
  if (outputUnit === 'mi/h') outputUnit = 'm/h';

  const convertedSpeed = convert(speed).from(inputUnit).to(outputUnit);
  return `${convertedSpeed.toFixed(decimalCount)}${_outputUnit}`;
};


/**
 * Returns in any activites are from strava
 * @param {Object[]} activities
 * @param {string} activities[].source
 */
export const activitySourceFromStrava = (activities = []) =>
  activities.map(a => a.source).includes(activitySources.STRAVA);


/**
 * Checks if a label field is empty
 * @param {string|number} field
 */
export const labelFieldIsEmpty = field => ['', null, undefined].includes(field);


/**
 * Check if all fields for a label are empty
 * @param {Object} label
 * @param {string|number} label.title
 * @param {string|number} label.value
 */
export const labelFieldsAreEmpty = (label = {}) =>
  labelFieldIsEmpty(label.title) && labelFieldIsEmpty(label.value);


/**
 * Check if all print label fields are empty
 * @param {Object[]} labels
 * @param {string} labels[].title
 * @param {string} labels[].value
 */
export const allLabelFieldsEmpty = (labels = []) => labels.every(labelFieldsAreEmpty);


/**
 * Returns a bool to determine if a print has details or any combination thereof
 * @param {string} title
 * @param {string} secondaryTitle
 * @param {array} labels
 */
export const printHasDetails = (title, secondaryTitle, labels) =>
  !labelFieldIsEmpty(title) ||
  !labelFieldIsEmpty(secondaryTitle) ||
  !allLabelFieldsEmpty(labels);


/**
 * Should return true if the print's activities have elevation profile enabled
 * @param {object[]} activities
 * @param {number[]|string[]} activities[].elevations
 * @param {boolean} activities[].elevationProfile
 * @returns {boolean}
 */
export const printHasElevationProfileEnabled = (activities = []) =>
  !!activities.length &&
  activities.every(({ elevations, elevationProfile }) =>
    !!elevations && !!elevations.length && !!elevationProfile);


/**
 * Returns true if at least one activity has start and end nodes active
 *
 * @param {object[]} activities
 * @param {boolean} activities[].startNode
 * @param {boolean} activities[].endNode
 * @returns {boolean}
 */
export const printHasActivityEndpointsEnabled = (activities = []) =>
  !!activities.length &&
  activities.some(a => a.startNode && a.endNode);


/**
 * Returns the number of visible print labels
 * @param {Object[]} labels
 * @param {string} labels[].title
 * @param {string} labels[].value
 * @returns {number}
 */
export const countVisiblePrintLabels = (labels = []) =>
  labels.reduce((acc, label) => labelFieldsAreEmpty(label) ? acc : ++acc, 0);


/**
 * Returns all parts of a layout param string
 * @param {string} layout
 * @returns {string[]}
 */
export const layoutParams = (layout = '') => removeFalsy(layout.split('-'));


/**
 * Tests if the provided layout is of type 'top'
 * @param {string} layout
 * @returns {boolean}
 */
export const isLayoutTop = layout => layoutParams(layout).includes('top');


/**
 * Tests if the provided layout is of type 'bottom'
 * @param {string} layout
 * @returns {boolean}
 */
export const isLayoutBottom = layout => layoutParams(layout).includes('bottom');


/**
 * Tests if the provided layout is of type 'vertical'
 * @param {string} layout
 * @returns {boolean}
 */
export const isLayoutVertical = layout => layoutParams(layout).includes('vertical');


/**
 * Tests if the provided layout is of type 'horizontal'
 * @param {string} layout
 * @returns {boolean}
 */
export const isLayoutHorizontal = layout => layoutParams(layout).includes('horizontal');


/**
 * Tests if the orientation is portrait
 * @param {string|ORIENTATION_PORTRAIT|ORIENTATION_LANDSCAPE} orientation
 */
export const isOrientationPortrait = orientation => orientation === ORIENTATION_PORTRAIT;


/**
 * Tests if the orientation is landscape
 * @param {string|ORIENTATION_PORTRAIT|ORIENTATION_LANDSCAPE} orientation
 */
export const isOrientationLandscape = orientation => orientation === ORIENTATION_LANDSCAPE;


/**
 * Tests if the specified theme is affiliated
 * @param {string} themeName
 */
export const isAffiliateTheme = themeName => eventThemeNames.includes(themeName);


/**
 * Creates a unique print ID
 *
 */
export const createPrintUuid = () => {
  let printId = `${printDatePrefix()}-${printUuid()}`;
  const env = process.env.REACT_APP_ENV;
  if (env !== 'production') {
    printId = `_${env.slice(0, 3)}_${printId}`;
  }
  return printId;
};


/**
 * Removes unwanted data from an activity object
 * Used before saving print to DB
 * @param {object} activity
 */
export const removeUnusedActivityData = activity => _omit(activity, [
  'polyline',
]);


/**
 * Removes unwanted data from a print object
 * Used before saving print to DB
 * @param {object} print
 */
export const removeUnusedPrintData = print => _omit(print, [
  'options',
]);


/**
 * Find the layer id where the map should render activities above
 * loading map styles as strings will present undefined for the layers,
 * so default to array
 * @param {object[]} mapLayers
 * @param {string} maplayers[].id
 */
export const findRenderBeforeLayerId = (mapLayers = []) => mapLayers
  .map(styleLayer => styleLayer.id)
  .find(layerId => /(place|peak)+/i.test(layerId));
