import React from 'react'
import _get from 'lodash.get'
import { compose } from 'redux'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _debounce from 'lodash.debounce'
import { uiState } from 'react-redux-ui-state'

import { media } from '../../styles'
import * as routes from '../../routes'
import { DeviceScreen } from '../../utils/dom'
import { calcCartOriginalTotal, calcCartTotal } from '../../utils/cart'
import { historyPropTypes } from '../../proptypes'
import { formatCurrency } from '../../utils/numbers'
import { colours, fonts, shadows, easings } from '../../theme'
import { mediaSizes, getPrintRatio, getPrintSize } from '../../utils/sizes'

import WithPrint from '../../hocs/WithPrint'
import WithThemes from '../../hocs/WithThemes'
import WithSession from '../../hocs/WithSession'
import WithAnalytics from '../../hocs/WithAnalytics'

import Print from '../../components/Print'
import SiteHeader from '../../containers/SiteHeader'
import { FeaturedTitle } from '../../components/Title'
import PrintDetails from '../../components/PrintDetails'
import SiteContainer from '../../components/SiteContainer'
import ElevationProfile from '../../components/ElevationProfile'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount'
import { LinkAsPrimaryButton, LinkAsButton } from '../../components/Link'
import MapboxMap, {
  propTypes as mapPropTypes,
} from '../../components/MapboxMap'
import { OriginalPrice } from '../../components/Price'

const Inner = styled.div``
const Outer = styled.div``
const Wrapper = styled.div``
const Buttons = styled.div``

const EditButton = styled(LinkAsButton)``
const CheckoutButton = styled(LinkAsPrimaryButton)``

const PADDING = 30 // px

class Preview extends React.Component {
  static propTypes = {
    history: historyPropTypes.isRequired,
    themeMapStyles: mapPropTypes.mapStyles.isRequired,
    printSize: PropTypes.shape({}).isRequired,
    mapComponentProps: PropTypes.shape({}).isRequired,
    printComponentProps: PropTypes.shape({}).isRequired,
    printDetailsComponentProps: PropTypes.shape({}).isRequired,
    elevationProfileComponentProps: PropTypes.shape({}).isRequired,
    hasElevationProfile: PropTypes.bool.isRequired,
    hasTouch: PropTypes.bool.isRequired,
    currentProduct: PropTypes.shape({}),
    printQuantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    printId: PropTypes.string,

    setUiState: PropTypes.func.isRequired,
    trackCtaClicked: PropTypes.func.isRequired,
  }

  static defaultProps = {
    currentProduct: undefined,
    printId: undefined,
  }

  constructor(props) {
    super(props)
    if (!props.printId) {
      this.props.history.replace(routes.CREATE)
      return
    }
    this.updatePrintScale()
  }

  componentDidMount() {
    this._screen = new DeviceScreen()
    window.addEventListener('resize', this.onWindowResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize)
  }

  onWindowResize = _debounce(() => {
    if (this._screen.mobileDeviceUiTriggeredResize(this.props.hasTouch)) return
    this.updatePrintScale()
  }, 800)

  updatePrintScale = () =>
    this.props.setUiState({
      uiPrintScale: getPrintScale(this.props),
    })

  trackCtaClicked = (label) => () => this.props.trackCtaClicked(label)

  get totalPrice() {
    return calcCartTotal(this.props.currentProduct, this.props.printQuantity)
  }

  get priceFormatted() {
    const currency = _get(this.props, 'currentProduct.currency', 'GBP')
    return formatCurrency(this.totalPrice / 100, currency)
  }

  get originalPrice() {
    const quantityPrice = calcCartOriginalTotal(
      this.props.currentProduct,
      this.props.printQuantity,
    )
    if (quantityPrice === 0) return null
    const currency = _get(this.props, 'currentProduct.currency', 'GBP')
    return formatCurrency(quantityPrice / 100, currency)
  }

  render() {
    const {
      className,
      currentProduct,
      themeMapStyles,
      mapComponentProps,
      hasElevationProfile,
      printComponentProps,
      printDetailsComponentProps,
      elevationProfileComponentProps,
    } = this.props

    const productTitle = _get(currentProduct, 'title')

    return (
      <React.Fragment>
        <ScrollToTopOnMount />
        <Helmet>
          <title>Preview</title>
        </Helmet>
        <div className={className}>
          <SiteHeader />
          <SiteContainer>
            <header>
              <FeaturedTitle tag="h1">
                Your print will be {productTitle} and{' '}
                {this.originalPrice && (
                  <OriginalPrice style={{ display: 'inline' }}>
                    {this.originalPrice}
                  </OriginalPrice>
                )}{' '}
                {this.priceFormatted}. Please make sure you’re happy with
                everything below&hellip;
              </FeaturedTitle>
              <Buttons>
                <EditButton
                  onClick={this.trackCtaClicked('Back to edit')}
                  to={routes.CREATE}
                >
                  <span>←</span> Back to Edit
                </EditButton>
                <CheckoutButton to={routes.CHECKOUT}>
                  Order <span>→</span>
                </CheckoutButton>
              </Buttons>
            </header>
          </SiteContainer>
          <Wrapper>
            <Outer>
              <Inner>
                <Print {...printComponentProps} mapStyles={themeMapStyles}>
                  <MapboxMap
                    {...mapComponentProps}
                    animate={false}
                    attribution={false}
                    controls={false}
                    interactive={false}
                    styles={themeMapStyles}
                  />
                  {hasElevationProfile && (
                    <ElevationProfile {...elevationProfileComponentProps} />
                  )}
                  <PrintDetails {...printDetailsComponentProps} />
                </Print>
              </Inner>
            </Outer>
          </Wrapper>
        </div>
      </React.Fragment>
    )
  }
}

const StyledPreview = styled(Preview)`
  background-color: ${colours.grey06};
  width: 100%;

  ${FeaturedTitle} {
    text-align: center;
    margin: 0 auto 6rem;
    max-width: 18em;

    position: relative;

    &:before {
      line-height: 1;
      content: '1 / 2';
      color: ${colours.grey03};
      font-size: ${fonts.size01};
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: -5rem;
    }
  }

  ${Buttons} {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  ${EditButton},
  ${CheckoutButton} {
    height: 8.8rem;
    max-width: 40rem;

    > span {
      transition: 0.2s ${easings.expoOut} transform;
      transform: translateX(0);
      margin: 0.1em 0.4em 0;
    }
  }

  ${EditButton} {
    margin-right: 3rem;
    font-size: ${fonts.size03};
    background-color: transparent;
    &:active,
    &:hover {
      color: ${colours.white};
      background-color: ${colours.primary};
    }
    &:hover > span {
      transform: translateX(-4px);
    }
  }

  ${CheckoutButton} {
    &:hover > span {
      transform: translateX(4px);
    }
  }

  ${Wrapper} {
    width: 100%;
    padding: 0 ${PADDING}px;
    /* overflow contains the zoom in mobile and prevents
    some resize bugs because the winodw will think the updated
    scale of the print should be visible */
    overflow: hidden;
  }

  ${Outer} {
    margin: 16rem auto 25rem;
    width: 100%;
    position: relative;
    max-width: ${(p) => getPrintSize(p.printOrientation).width}px;
  }

  ${Inner} {
    width: 100%;
    height: 0;
    padding-bottom: ${(p) => getPaddingPercentage(p)}%;
    margin: auto;
    box-shadow: ${shadows.printFrame};
  }

  ${Print} {
    position: absolute;
    top: 0;
    left: 0;
    width: ${(p) => p.printSize.width}px;
    height: ${(p) => p.printSize.height}px;
    pointer-events: none;
    transform: scale(${(p) => p.uiPrintScale});
    transform-origin: top left;
    /* do not use will-change here - crashes zooming on mobile */
    transition: 0.2s ease-out transform;
  }

  ${media.nav`
    ${FeaturedTitle} {
      margin-bottom: 5rem;
      br { display: none; }
      &:before {
        top: -3rem;
      }
    }

    ${Buttons} {
      flex-direction: column-reverse;
    }
    ${EditButton} {
      margin-right: 0;
      margin-top: 2rem;
    }

    ${EditButton},
    ${CheckoutButton} {
      font-size: ${fonts.size02};
      height: auto;
    }
    ${Outer} {
      margin: 8rem auto 10rem;
    }
  `}
`

// TODO: perhaps these could be combined with the helpers in Create

export const getPaddingPercentage = (props) => {
  const ratio = getPrintRatio(props.printOrientation)
  return ((1 / ratio) * 100).toFixed(2)
}

export const getPrintScale = (props) => {
  const availableWindowWidth = getAvailableWindowWidth(props)
  const maxWidth = Math.min(props.printSize.width, availableWindowWidth)
  return (maxWidth / props.printSize.width).toFixed(4)
}

export const getAvailableWindowWidth = (props) => {
  const windowWidth = window.innerWidth
  const isMobileSize = windowWidth <= mediaSizes.create && props.hasTouch
  const scrollbarWidth = isMobileSize ? 0 : 16
  const sitePadding = PADDING * 2
  return windowWidth - scrollbarWidth - sitePadding
}

const { uiPrintScale } = Preview.defaultProps
const withUiState = uiState({
  name: () => 'Preview',
  persist: false,
  state: () => ({
    uiPrintScale,
  }),
})

const withPrint = WithPrint()
const withThemes = WithThemes()
const withSession = WithSession()
const withAnalytics = WithAnalytics()

export { Preview }
export default compose(
  withThemes,
  withPrint,
  withSession,
  withAnalytics,
  withUiState,
)(StyledPreview)
