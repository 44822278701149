import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import _get from 'lodash.get';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import { media } from '../../styles';
import * as routes from '../../routes';
import { locationPropTypes, historyPropTypes } from '../../proptypes';

import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import { queryStringToObject } from '../../utils/strings';
import WithSession from '../../hocs/WithSession';
import WithPrint from '../../hocs/WithPrint';

import { Logo } from '../../components/Logo';
import { FeaturedTitle } from '../../components/Title';
import SiteContainer from '../../components/SiteContainer';

class UnstyledStravaAuth extends React.Component {
  static propTypes = {
    isAuthedWithStrava: PropTypes.bool.isRequired,
    getStravaAccessToken: PropTypes.func.isRequired,
    location: locationPropTypes.isRequired,
    history: historyPropTypes.isRequired,
  };


  constructor(props) {
    super(props);

    if (this.props.isAuthedWithStrava) {
      this.hopefullyReturnToCreatePage(props);
    }

    const locationSearch = this.props.location.search;
    const queryParams = queryStringToObject(locationSearch);
    // TODO: check for failure/error
    // error=access_denied in query params
    const authToken = queryParams.code;
    if (authToken) {
      this.props.getStravaAccessToken(authToken);
    } else {
      this.props.history.replace(routes.HOME);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isAuthedWithStrava !== this.props.isAuthedWithStrava) {
      this.hopefullyReturnToCreatePage(nextProps);
    }
  }

  hopefullyReturnToCreatePage(props) {
    const printId = _get(props, 'print.id', undefined);
    if (printId) {
      setTimeout(() => {
        this.props.history.replace(`${routes.CREATE}/${printId}`);
      }, 2000);
    } else {
      this.props.history.replace(routes.HOME);
    }
  }

  render() {
    return (
      <div className={this.props.className}>
        <ScrollToTopOnMount />
        <Helmet>
          <title>Authing with Strava</title>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <SiteContainer>
          <Logo />
          <FeaturedTitle>Nearly done&hellip;</FeaturedTitle>
        </SiteContainer>
      </div>
    );
  }
}

const StravaAuth = styled(UnstyledStravaAuth)`
  width: 100%;

  ${FeaturedTitle} {
    max-width: 24em;
    text-align: center;
    margin-top: 10rem;
  }

  ${SiteContainer} {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
  }

  ${media.nav`
    ${SiteContainer} {
      padding-top: 4rem;
      padding-bottom: 8rem;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
    }

    ${Logo} {
      width: 15rem;
    }
    ${FeaturedTitle} {
      width: 100%;
      text-align: center;
      margin-bottom: 6rem;
      margin-top: 6rem;
    }
  `}
`;

const withSession = WithSession();
const withPrint = WithPrint();

export { StravaAuth };
export default compose(
  withSession,
  withPrint,
)(StravaAuth);
