export const ORDERS_DOMAIN = 'orders';

export const SET_ORDER_IN_PROGRESS = 'SET_ORDER_IN_PROGRESS';

// Stripe
export const CREATE_STRIPE_ORDER_REQUEST = 'CREATE_STRIPE_ORDER_REQUEST';
export const SET_STRIPE_ACTION_REQUIRED = 'SET_STRIPE_ACTION_REQUIRED';
export const CONFIRM_STRIPE_ORDER_REQUEST = 'CONFIRM_STRIPE_ORDER_REQUEST';

// PayPal
export const CREATE_PAYPAL_ORDER_REQUEST = 'CREATE_PAYPAL_ORDER_REQUEST';

// Prints
export const GET_PRINT_ORDER_REQUEST = 'GET_PRINT_ORDER_REQUEST';
