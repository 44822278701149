import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import * as routes from '../../routes';
import { colours, fonts } from '../../theme';
import { media } from '../../styles';

import WithThemes from '../../hocs/WithThemes';
import WithEvents from '../../hocs/WithEvents';
import WithSession from '../../hocs/WithSession';
import { eventPropTypes } from '../../hocs/WithEvents/model';

import SiteHeader from '../SiteHeader';
import SiteContainer from '../../components/SiteContainer';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import { FeaturedTitle, SectionTitle, Title } from '../../components/Title';
import EventCard, { EventTitle, EventCardPlaceholder } from '../../components/EventCard';
import Emoji from '../../components/Emoji';
import { LinkAsPrimaryButton } from '../../components/Link';

const { REACT_APP_SITE } = process.env;
const description = `Select your event organiser to get started`;
const title = "Events / Paper Trails";

const CreateTitle = styled(Title)`
`;
const CreateLink = styled(LinkAsPrimaryButton)``;
const PageTitle = styled(Title)`
  font-size: 12rem;
  line-height: ${fonts.lineHeight01};

  ${media.footer`
    font-size: 8rem;
  `}

  ${media.nav`
    font-size: 6rem;
  `}
`;

const UnstyledEvents = props => (
  <React.Fragment>
    <ScrollToTopOnMount />
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:url" content={`${REACT_APP_SITE}${routes.EVENTS}`} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
    </Helmet>
    <div className={props.className}>
      <SiteHeader />
      <SiteContainer>
        <article>
          <header className="page-header">
            <PageTitle tag="h1">Events</PageTitle>
            <FeaturedTitle tag="h2">{description}</FeaturedTitle>
          </header>
          <section>
            <ul className="events-list">
              {props.events.map(affiliate => (
                <li className="events-item" key={affiliate.uri}>
                  <EventCard
                    to={`${routes.EVENTS}/${affiliate.uri}`}
                    image={affiliate.badge}
                    title={affiliate.title}
                  />
                </li>
              ))}
              <li className="events-item">
                <EventCardPlaceholder />
              </li>
              {/* <li className="events-item">
                <EventCardPlaceholder />
              </li> */}
            </ul>
          </section>
        </article>

        <aside className="create">
          <CreateTitle tag="h4">Cherish your own adventure, from {props.cheapestProductPriceFormatted}.</CreateTitle>
          <CreateLink to={routes.CREATE}>Let's get started!</CreateLink>
        </aside>

      </SiteContainer>
    </div>
  </React.Fragment>
);

UnstyledEvents.propTypes = {
  events: PropTypes.arrayOf(eventPropTypes).isRequired,
};
UnstyledEvents.defaultProps = {

};

const Affiliates = styled(UnstyledEvents)`
  width: 100%;
  overflow: hidden;
  position: relative;

  ${SiteHeader} {
    ${SiteContainer} {
      padding-bottom: 12rem;
    }
  }

  ${PageTitle} {
    margin-bottom: 0.53em;
  }

  ${FeaturedTitle} {
    margin: 0 auto 10rem;
    max-width: 21em;
  }

  ${SectionTitle} {
    margin-bottom: 1.4rem;
  }

  .page-header {
    text-align: center;

    ${Emoji} {
      display: block;
      margin: 0 auto 0;
      font-size: 52px;
    }
  }

  .events-list {
    margin: 10rem auto 30rem;
    backface-visibility: hidden;
    display: grid;
    grid-template-columns: repeat(3, minmax(30rem, 1fr));
    grid-gap: 14rem 6.5rem;
  }

  .events-item {
    max-width: 420px;
  }

  .create {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10rem 0;
    position: relative;

    &:before {
      content: '';
      border-top: 2px solid ${colours.grey02};
      position: absolute;
      width: 100vw;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  ${CreateTitle} {
    margin-bottom: 5rem;
  }

  ${CreateLink} {
    text-decoration: none;
    font-size: ${fonts.size03};
    height: 8.8rem;
    max-width: 42rem;
  }


  ${media.create`
    .events-list {
      grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
      grid-gap: 12vw 4.5vw;
    }
    .events-item {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
    ${EventTitle} {
      font-size: ${fonts.size03};
    }
  `}

  ${media.footer`
    ${SiteHeader} {
      ${SiteContainer} {
        padding-bottom: 10rem;
      }
    }

    ${EventTitle} {
      margin-top: 1em;
      font-size: ${fonts.size03};
    }
  `}

  ${media.nav`
    ${SiteHeader} {
      ${SiteContainer} {
        padding-bottom: 8rem;
      }
    }

    ${FeaturedTitle} {
      margin-bottom: 8rem;
    }
    .events-list {
      margin-top: 7.5rem;
      margin-bottom: 10rem;
    }

    .create {
      padding: 6rem 0 8rem;
    }

    ${CreateTitle} {
      font-size: ${fonts.size03};
    }

    ${CreateLink} {
      font-size: ${fonts.size02};
    }
  `}

`;

const withSession = WithSession();
const withThemes = WithThemes();
const withEvents = WithEvents();

export { Affiliates, UnstyledEvents };
export default compose(withSession, withThemes, withEvents)(Affiliates);
