export default {
  email: {
    contact: 'contact@papertrails.io',
  },
  company: {
    name: 'Paper Trails Map Company Ltd',
    number: '11914935',
    registered: 'England and Wales',
    website: 'https://papertrails.io',
  },
};
