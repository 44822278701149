import Immutable from 'seamless-immutable'
import PropTypes from 'prop-types'

import {
  ORIENTATION_PORTRAIT,
  ORIENTATION_LANDSCAPE,
} from '../../hocs/WithPrint/constants'

// import thirteenpeaks from './thirteenpeaks';
import gloriousgravel from './gloriousgravel'
import pancelticgravelrally from './pancelticgravelrally'
import pancelticultra from './pancelticultra'
import gbDivide from './gbdivide'
import tribexmaverick from './tribexmaverick'

export const eventPropTypes = PropTypes.shape({
  description: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  logo: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  uri: PropTypes.string,
  themes: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      title: PropTypes.string,
      themeName: PropTypes.string,
      orientation: PropTypes.oneOf([
        ORIENTATION_PORTRAIT,
        ORIENTATION_LANDSCAPE,
      ]),
    }),
  ),
})

export const events = [
  // thirteenpeaks,
  pancelticultra,
  tribexmaverick,
  gbDivide,
  pancelticgravelrally,
  gloriousgravel,
]

// IMPORTANT: don't forget to add event URIs to the pre-build script

export const eventUris = events.map((event) => event.uri)

export const initialState = Immutable({
  events,
  eventThemeNames: []
    // .concat(thirteenpeaks.themeNames)
    .concat(gloriousgravel.themeNames)
    .concat(pancelticgravelrally.themeNames)
    .concat(pancelticultra.themeNames)
    .concat(tribexmaverick.themeNames)
    .concat(gbDivide.themeNames),
})
