import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactAnalytics from 'react-ga';
import { bindActionCreators, compose } from 'redux';

import * as routes from '../../routes';
import * as Actions from './actions';

const { REACT_APP_ENV, REACT_APP_GOOGLE_ANALYTICS, REACT_APP_GOOGLE_ADS,
  REACT_APP_DEBUG_ANALYTICS, REACT_APP_ANALYTICS_ON } = process.env;
const ENV_UPPER = REACT_APP_ENV.toUpperCase();

export const TRACKERS = {
  googleAnalytics: REACT_APP_GOOGLE_ANALYTICS,
  googleAds: REACT_APP_GOOGLE_ADS,
};

// ONly track Google Ads metrics in production
if (ENV_UPPER !== 'PRODUCTION') delete TRACKERS.googleAds;

export const TRACKER_KEYS = Object.keys(TRACKERS);


export const initAnalytics = () => {
  if (!ReactAnalytics.ga()) {
    if (REACT_APP_ANALYTICS_ON) {
      ReactAnalytics.initialize(TRACKER_KEYS.map(tracker => ({
        trackingId: TRACKERS[tracker],
        gaOptions: {
          name: tracker,
        },
      })));
    }
  }
};


/**
 * Tracks events
 *
 * @param {string} page
 */
export const trackPage = (page) => {
  if (!ReactAnalytics.ga()) {
    initAnalytics();
  }
  if (REACT_APP_ANALYTICS_ON) {
    if (page === routes.STRAVA_AUTH) {
      // sending a null referrer means authing with Strava won't show up as
      // additional referrer traffic
      ReactAnalytics.set({ referrer: null });
    }
    ReactAnalytics.pageview(page, TRACKER_KEYS);
  }
  if (REACT_APP_DEBUG_ANALYTICS) {
    console.group(`Analytics Page [${ENV_UPPER}]`);
    console.info('Page:', page);
    console.groupEnd();
  }
};


/**
 * Tracks events
 *
 * @param {object} action
 * @param {string} action.category
 * @param {string} action.action
 * @param {string} action.label
 * @param {string|number} action.value
 * @param {boolean} action.nonInteraction
 */
export const trackEvent = ({ category, action, label = '', value, nonInteraction }) => {
  if (REACT_APP_ANALYTICS_ON) {
    ReactAnalytics.event({ category, action, label, value, nonInteraction }, TRACKER_KEYS);
  }
  if (REACT_APP_DEBUG_ANALYTICS) {
    console.group(`Analytics Event [${ENV_UPPER}]`);
    console.info('Event:', `${category} / ${action} / ${label} / ${value}`);
    console.groupEnd();
  }
};


/**
 * Tracks exceptions
 *
 * @param {string} description
 * @param {boolean} fatal
 */
export const trackException = (description = '', fatal = false) => {
  if (REACT_APP_ANALYTICS_ON) {
    ReactAnalytics.exception({ description, fatal }, TRACKER_KEYS);
  }
  if (REACT_APP_DEBUG_ANALYTICS) {
    console.group(`Analytics Exception [${ENV_UPPER}]`);
    console.info('Fatal:', fatal);
    console.info(description);
    console.groupEnd();
  }
};


const PageAnalytics = WrappedComponent =>
  // eslint-disable-next-line
   class WithHoc extends React.Component {

    static displayName = `withPageAnalytics(${
      WrappedComponent.displayName ||
      WrappedComponent.name ||
      'Component'
    })`;

    static propTypes = {
      location: PropTypes.shape({
        pathname: PropTypes.string,
      }).isRequired,
      history: PropTypes.shape({
        action: PropTypes.string,
      }).isRequired,
      trackPage: PropTypes.func.isRequired,
    }

    constructor(props) {
      super(props);
      initAnalytics();
    }

    componentDidMount() {
      this.trackPage(this.props.location.pathname);
    }

    componentWillReceiveProps(nextProps) {
      const currentPage = this.props.location.pathname;
      const nextPage = nextProps.location.pathname;

      if (currentPage !== nextPage && nextProps.history.action !== 'REPLACE') {
        this.trackPage(nextProps.location.pathname);
      }
    }

    trackPage(pathname) {
      setTimeout(() => {
        this.props.trackPage(pathname);
      }, 200);
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
export const MakeWithPageAnalyticsHoc = WrappedComponent =>
  new PageAnalytics(WrappedComponent);

export const WithPageAnalytics = () => (WrappedComponent) => {
  const mapDispatchToProps = dispatch => bindActionCreators({
    trackPage: Actions.trackPage,
    trackEvent: Actions.trackEvent,
    trackCtaClicked: Actions.trackCtaClicked,
    trackError: Actions.trackError,
    trackException: Actions.trackException,
  }, dispatch);

  const withAnalytics = MakeWithPageAnalyticsHoc(WrappedComponent);
  const withConnect = connect(null, mapDispatchToProps);

  return compose(withConnect)(withAnalytics);
};


export default WithPageAnalytics;
