/**
 * Takes an object of form data and maps it to Stripe's format
 * @param {object} formData
 *
 */
export const makeStripeBillingDetails = (formData = {}) => {
  return {
    name: formData.name,
    email: formData.email,
    phone: formData.phone_mobile,
    address: {
      city: formData.address_city,
      country: formData.address_country,
      line1: formData.address_line1,
      line2: formData.address_line2,
      postal_code: formData.address_zip,
      state: formData.address_state,
    },
  };
};
