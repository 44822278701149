export const HOME = '/';
export const FAQ = '/faq';
export const FOUR_OH_FOUR = '/404';
export const ABOUT = '/about';
export const THANKS = '/thanks';
export const CHECKOUT = '/checkout';
export const STRAVA_AUTH = '/strava-auth';
export const LINKS = '/links';
export const SHOP = 'https://shop.papertrails.io';


// -----------------------------------------------------------------------------
// CREATING
// -----------------------------------------------------------------------------
export const CREATE = '/create';
export const CREATE_UUID = 'uuid';
export const PREVIEW = '/preview';


// -----------------------------------------------------------------------------
// GENERATING
// -----------------------------------------------------------------------------
export const GENERATE = '/generate';
export const GENERATE_UUID = 'uuid';


// -----------------------------------------------------------------------------
// EVENTS
// -----------------------------------------------------------------------------
export const EVENTS = '/events';
export const EVENT_UUID = 'event';
export const EVENTS_DETAIL = `/events/:${EVENT_UUID}`;


// -----------------------------------------------------------------------------
// SOCIAL
// -----------------------------------------------------------------------------
export const INSTAGRAM = 'https://instagram.com/papertrails.io';
export const FACEBOOK = 'https://facebook.com/papertrails.io';
export const TWITTER = 'https://twitter.com/papertrailsio';


// -----------------------------------------------------------------------------
// TC's + PRIVACY + FAQ's
// -----------------------------------------------------------------------------
export const TERMS_AND_CONDITIONS = '/terms-and-conditions';
export const PRIVACY_POLICY = '/privacy-policy';
export const COOKIE_POLICY = '/privacy-policy#cookies';
export const FAQ_SHIPPING = '/faq#shipping';


// -----------------------------------------------------------------------------
// EXTERNAL / THIRD_PARTY
// -----------------------------------------------------------------------------
export const PRODIGI_PRINTS = 'https://prodigi.uk/products/prints-posters/fine-art-prints/';
export const PRODIGI = 'https://prodigi.uk/';
