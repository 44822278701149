import reddead from './reddead'
import heather from './heather'
import lingerie from './lingerie'
import blueprint from './blueprint'
import moonraker from './moonraker'
import mintymiles from './mintymiles'
import coppermine from './coppermine'
import theclassic from './theclassic'
import swampthing from './swampthing'
import paperchase from './paperchase'
import crimsonride from './crimsonride'
import americanrust from './americanrust'
import outlines1 from './outlines1'
import outlines2 from './outlines2'

// import thirteenpeaksoneday from './thirteenpeaksoneday';
// import thirteenpeakstwoday from './thirteenpeakstwoday';
// import thirteenpeaksmultiday from './thirteenpeaksmultiday';

import ggdorsetgravelx from './gloriousgravel/ggdorsetgravelx'
import ggpeakdistrictgravelx from './gloriousgravel/ggpeakdistrictgravelx'
import ggnorthwalesgravelx from './gloriousgravel/ggnorthwalesgravelx'
import ggsherwoodgravelx from './gloriousgravel/ggsherwoodgravelx'
import ggsuffolkgravelx from './gloriousgravel/ggsuffolkgravelx'

import pcGravelRally2022Full from './panceltic/pcgravelrally2022full'
import pcGravelRally2022Short from './panceltic/pcgravelrally2022short'

import pcUltra2022Full from './pancelticultra/pcultra2022full'
import pcUltra2022Short from './pancelticultra/pcultra2022short'
import pcUltra2022Mixed from './pancelticultra/pcultra2022mixed'
import pcUltra2023Full from './pancelticultra2023/pcultra2023full'
import pcUltra2023Short from './pancelticultra2023/pcultra2023short'
import pcUltra2023Mixed from './pancelticultra2023/pcultra2023mixed'

import gbDivide2021 from './gbdivide/gbdivide2021'
import gbDivide2022 from './gbdivide/gbdivide2022'
import gbDivide2023full from './gbdivide/gbdivide2023full'
import gbDivide2023short from './gbdivide/gbdivide2023short'

import tribexmaverickultra2022 from './tribexmaverick/tribexultra2022'
import tribexmaverickmarathon2022 from './tribexmaverick/tribexmarathon2022'
import tribexmaverickhalf2022 from './tribexmaverick/tribexhalf2022'
import tribexmaverickshort2022 from './tribexmaverick/tribexshort2022'

export default {
  [reddead.id]: reddead,
  [heather.id]: heather,
  [lingerie.id]: lingerie,
  [blueprint.id]: blueprint,
  [moonraker.id]: moonraker,
  [mintymiles.id]: mintymiles,
  [coppermine.id]: coppermine,
  [theclassic.id]: theclassic,
  [swampthing.id]: swampthing,
  [paperchase.id]: paperchase,
  [crimsonride.id]: crimsonride,
  [americanrust.id]: americanrust,
  [outlines1.id]: outlines1,
  [outlines2.id]: outlines2,

  // affiliate themes

  // [thirteenpeaksoneday.id]: thirteenpeaksoneday,
  // [thirteenpeakstwoday.id]: thirteenpeakstwoday,
  // [thirteenpeaksmultiday.id]: thirteenpeaksmultiday,

  [ggdorsetgravelx.id]: ggdorsetgravelx,
  [ggpeakdistrictgravelx.id]: ggpeakdistrictgravelx,
  [ggsherwoodgravelx.id]: ggsherwoodgravelx,
  [ggsuffolkgravelx.id]: ggsuffolkgravelx,
  [ggnorthwalesgravelx.id]: ggnorthwalesgravelx,

  [pcGravelRally2022Full.id]: pcGravelRally2022Full,
  [pcGravelRally2022Short.id]: pcGravelRally2022Short,

  [pcUltra2022Full.id]: pcUltra2022Full,
  [pcUltra2022Short.id]: pcUltra2022Short,
  [pcUltra2022Mixed.id]: pcUltra2022Mixed,
  [pcUltra2023Full.id]: pcUltra2023Full,
  [pcUltra2023Short.id]: pcUltra2023Short,
  [pcUltra2023Mixed.id]: pcUltra2023Mixed,

  [gbDivide2021.id]: gbDivide2021,
  [gbDivide2022.id]: gbDivide2022,
  [gbDivide2023full.id]: gbDivide2023full,
  [gbDivide2023short.id]: gbDivide2023short,

  [tribexmaverickultra2022.id]: tribexmaverickultra2022,
  [tribexmaverickmarathon2022.id]: tribexmaverickmarathon2022,
  [tribexmaverickhalf2022.id]: tribexmaverickhalf2022,
  [tribexmaverickshort2022.id]: tribexmaverickshort2022,
}
